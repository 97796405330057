import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Box, Card, Checkbox, Grid, MenuItem, Select, TextField, Typography, Stack, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, useFieldArray, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import Iconify from '../../../../../components/Iconify';
import { countries } from '../../../../../_mock';

import { FormProvider, RHFDatePicker, RHFTextField, RHFCheckbox, RHFRadioGroup, RHFSelect } from '../../../../../components/hook-form';
import { fDateDb } from '../../../../../utils/formatTime';
import NoAmountModal from '../../NoAmountModal';
import { DONATION_TYPE } from '../../../../../config';

import { DonationAPI } from '../../../../../services/donation.services';
import { convertImagesToBase64STring } from '../../../../../utils/resizeFile';
import UploadPhoto from '../../../../../sections/giving_basket/UploadPhoto';

const GOODS_OPTIONS = [
  'clothes',
  'blanket/ linen',
  'books',
  'school supplies',
  'food',
  'equipment',
  'furniture',
  'hygiene essentials',
  'medicines/medical supplies',
  'others, specify',
];

export default function CheckForm({ discretionary, projects, causes, disabled, onSuccess }) {

  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noAmountOpen, setNoAmountOpen] = useState(false);

  const resolver = yupResolver(Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    donationAmount: Yup.string().required('Required'),
    file: Yup.array().min(1, "Please select a photo").required('Required'),
    items: Yup.array().of(Yup.object().shape({
      goods: Yup.string().required('Required'),
      quantity: Yup.number().required('Required')
    })),
    dropAddress: Yup.string().when('pickup', {
      is: false,
      then: Yup.string().required('Required')
    }),
    pickupAddress: Yup.string().when('pickup', {
      is: true,
      then: Yup.string().required('Required')
    })
  }))

  const defaultValues = {
    items: [{}]
  }

  const methods = useForm({ defaultValues, resolver });

  const {
    reset,
    setError,
    handleSubmit,
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data) => {
    if (!data.donationAmount) {
      setNoAmountOpen(true);
      return;
    }

    setIsLoading(true);
    const payload = await createPayload({
      causes,
      discretionary,
      formData: data,
      projects,
      images: data.file
    })
    DonationAPI.submitDonationInHouse(DONATION_TYPE.IN_HOUSE.GOODS, payload)
      .then((res) => {
        reset(defaultValues);
        setIsLoading(false);
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        setIsLoading(false)
        alert(`Encountered an error. Please try again.${err}`);
      });
  }

  const addItem = () => {
    setValue('items', [...values.items, {}])
  }

  const handleRemove = (index) => {
    const items = [...values.items];
    items.splice(index, 1);
    setValue('items', items);
  }

  const options = [
    'Unit 204, 2/F Vivaldi Condominium, 628 EDSA, Cubao, Quezon City',
    '5D Pinatubo St., Bgy. San Martin de Porres, Cubao, Quezon City',
  ];

  const channels = [
    {
      text: 'Call us via out landline number at  +63 28',
    },
    {
      text: 'Message or call us via our mobile number +63',
    },
    {
      text: 'Email us at contact@charityphilippines.org',
    },
    {
      text: 'Or fill up the donation form below then we will connect with you',
    },
  ];

  useEffect(() => {
    setValue('donationAmount', projects.reduce((prev, val) => prev + val.amount, 0));
  }, [projects])

  const setFile = (file) => {
    if (file.size > 1024 * 500) {
      setValue("file", [file]);
    } else {
      setValue("file", selectedImages);
    }
    clearErrors('file');
  }

  useEffect(() => {
    const file = selectedImages[0];
    if (!file) return;

    setFile(file);
  }, [selectedImages]);

  useEffect(() => {
    if (values.pickup) {
      setValue('dropAddress', '')
    }
  }, [values.pickup])


  return (
    <Box>
      <Typography fontWeight={700}>Goods</Typography>
      <Typography variant="body1">
        Donate brand new or used goods to this project.
      </Typography>

      {channels.map((channel, idx) => (
        <Stack key={idx} spacing={1} direction="row" alignItems="center" ml={2} mt={1}>
          <div>
            <Iconify icon={'bx:chevron-right'} sx={{ width: { xs: 11, md: 15 }, height: { xs: 11, md: 15 } }} />
          </div>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>{channel.text}</Typography>
        </Stack>
      ))}

      <Typography variant="body1" mb={3} mt={2}>
        Please coordinate with us regarding arrangements for drop-off or pick-up of goods through these channels:
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={6}>
            <RHFTextField autoFocus fullWidth label="First Name" name='firstName' />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFTextField fullWidth label="Last Name" name='lastName' />
          </Grid>
          <Grid item container spacing={1} xs={12}>
            <Grid item xs={12}>
              <RHFTextField fullWidth label="Email" name='email' />
            </Grid>
            <Grid item xs={12}>
              <Typography px={2} variant="caption">We will send your donation receipt to the email address provided.</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RHFTextField fullWidth label="Address" name='address' />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete value={countries.find(c => c.code === values.country)}
              fullWidth
              onChange={(ev, e) => setValue('country', e.code)} renderInput={(e) => <TextField label="Country" {...e} />}
              options={countries}
              getOptionLabel={(e) => e?.label ?? ''}
              isOptionEqualToValue={(a, b) => a.code === b.code}
            />
          </Grid>
          <Grid item container xs={12} md={6} direction='column' alignItems='start'>
            <RHFTextField fullWidth name='phone' label='Contact Number' />
            <Typography variant="caption">We will contact you in case we encounter issues with your donation.</Typography>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, mt: 4 }}>
          <Typography variant="h6" sx={{ color: 'text.disabled' }}>
            Items to be donated:
          </Typography>

          <Button
            variant="contained"
            size="small"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={addItem}
            sx={{ flexShrink: 0, boxShadow: 0 }}
          >
            Add new Items
          </Button>
        </Box>

        {values.items.map(e => e.goods)}

        {values.items.map((item, index) => (
          <Grid container spacing={2} key={item.id} mb={2}>
            <Grid item xs={12} md={2}>
              <RHFSelect
                value={item.goods ?? ''}
                name={`items[${index}].goods`}
                label="Item type"
                size="small"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                <MenuItem
                  value=""
                  sx={{
                    mx: 1,
                    borderRadius: 0.75,
                    typography: 'body2',
                    fontStyle: 'italic',
                    color: 'text.secondary',
                  }}
                >
                  None
                </MenuItem>
                <Divider />
                {GOODS_OPTIONS.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            {values.items[index].goods === 'others, specify' && (
              <Grid item xs={12} md={4}>
                <RHFTextField
                  size="small"
                  name={`items[${index}].others`}
                  label="Others"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            <Grid item xs={12} md={2}>
              <RHFTextField
                size="small"
                type="number"
                value={item.quantity ?? ''}
                name={`items[${index}].quantity`}
                label="Quantity"
                onChange={(event) => {
                  setValue(`items[${index}].quantity`, event.target.value ? parseFloat(event.target.value) : '')
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {
              index !== 0 &&
              <Grid item xs={12} md={1}>
                <Button
                  size="small"
                  color="error"
                  startIcon={<Iconify icon="eva:trash-2-outline" />}
                  onClick={() => handleRemove(index)}
                >
                  Remove
                </Button>
              </Grid>
            }
          </Grid>
        ))}

        <UploadPhoto
          error={errors.file}
          name='file'
          label="Uploading photos of goods you want to donate helps us determine what we need to prepare for pick-up and will save you the time and effort of describing to us the goods you will be donating."
          selectedImages={selectedImages ?? []}
          setSelectedImages={setSelectedImages}
        />

        {
          !values.pickup &&
          <Box display={'flex'} alignItems={'flex-start'} mt={4} width={1} flexDirection={'column'}>
            <Typography variant="body1" sx={{ mr: 2 }}>
              I will drop-off donation items in:
            </Typography>
            <RHFRadioGroup
              name="dropAddress"
              options={options}
              sx={{
                '& .MuiFormControlLabel-root': { ml: 1 },
                '&': {
                  flexDirection: 'column'
                }
              }}
            />
          </Box>
        }


        <Grid mt={4} container alignItems='center'>
          <Grid item xs={2} md={.5} pl={1.5}>
            <RHFCheckbox name="pickup" label="" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body1" sx={{ mr: 2 }}>
              I will request for pick-up of donation items.{' '}
              <Typography
                component={'span'}
                sx={{ display: 'block', fontStyle: 'italic' }}
              >{`(Available in Metro Manila only.)`}</Typography>
            </Typography>
          </Grid>
        </Grid>

        {
          values.pickup &&
          <>
            <Typography mt={2} fontSize={16}>I will request for check pick-up in this address. (If not the address you provided above.)</Typography>
            <RHFTextField fullWidth name='pickupAddress' />
          </>
        }
        <Grid mt={2} container alignItems="center">
          <Grid item xs={2} md={.5}>
            <Checkbox />
          </Grid>
          <Grid item xs={10}>
            <Typography fontSize={16}>I am interested to receive CharityPhilippines.org's email updates</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" mt={2} spacing={2}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Where did you hear about CharityPhilippines.org?
          </Typography>
          <Grid item xs={3}>
            <Select value={values.charitySource ?? "_"} fullWidth onChange={(e) => setValue('charitySource', e.target.value)} sx={{minWidth: 200}}>
              <MenuItem value="_">Please Choose</MenuItem>
              <MenuItem value="Family or Friend">Family or Friend</MenuItem>
              <MenuItem value="Proactively Looking">Proactively Looking</MenuItem>
              <MenuItem value="Workplace">Workplace</MenuItem>
              <MenuItem value="Social Media">Social Media</MenuItem>
              <MenuItem value="Social Media Advertising">
                Social Media Advertising
              </MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {values.charitySource === "Others" && (
          <Box width={220}>
            <RHFTextField name="charityOtherSource" label="Specify Other" />
          </Box>
        )}
        <Grid container justifyContent='center' py={5}>
          <Grid item xs={4}>
            <LoadingButton loading={isLoading} fullWidth size="large" variant="contained" type="submit">SUBMIT</LoadingButton>
          </Grid>
        </Grid>
      </FormProvider>
      <NoAmountModal open={noAmountOpen} onClose={() => setNoAmountOpen(false)} />
    </Box>
  )
}

const createPayload = async ({ discretionary, formData, images, projects, causes }) => {
  const payload = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    contactNumber: formData.phone,
    address: formData.address ? formData.address : ' ',
    country: formData.country,
    donationItems: formData.items.map((item) => ({
      goods: item.goods === 'others, specify' ? item.others : item.goods,
      quantity: item.quantity,
    })),
    images,
    file: images,
    imagesBase64: [],
    dropAddress: formData.dropAddress,
    pickup: formData.pickup ?? false,
    pickupAddress: formData.pickupAddress === '' ? formData.address : formData.pickupAddress,
    emailUpdate: formData.emailUpdate ?? false,
    charitySource: formData.charitySource === 'Others' ? formData.charityOtherSource : formData.charitySource,
    projects: projects.map(e => ({ ...e, charityName: e.charityDetails.name, id: e._id })),
    discretion: {
      isDiscretion: discretionary,
      causes
    }
  }
  payload.imagesBase64 = await convertImagesToBase64STring(payload.images);
  return payload;
}