// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

export const CPO_API = process.env.REACT_APP_API_URL || "https://dev-api.charityphilippines.org";
export const MAYA_KEY = process.env.REACT_APP_MAYA_KEY;
export const CPO_URL = process.env.REACT_APP_CPO_URL;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const DONATION_TYPE = {
  IN_HOUSE:{
    BANK_TRANSFER_DEPOSIT:'BANK_TRANSFER_DEPOSIT',
    CHECK:'CHECK',
    GOODS:'GOODS',
    VOLUNTEER_SERVICE:'VOLUNTEER_SERVICE',
  },
  PAYMENT_GATEWAY:{
    MAYA:'MAYA'
  }
}

export const AcctApiUrl = 'http://172.104.62.66';
export const AcctApiAuth = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJkYXRhIjp7InVzZXJfdHlwZV9pZCI6IjEiLCJmdWxsX25hbWUiOiJMYWdhbiwgQnJ5YW4iLCJmaXJzdF9uYW1lIjoiQnJ5YW4iLCJlbWFpbCI6ImJyeWFubGFnYW5AZ21haWwuY29tIn0sInRpbWVzdGFtcCI6MTY3ODUzNTk5OH0.sc1LblmgHbsANo_CBFBJBpAsphksmxJ2wRqWKZeIA6g';
export const AcctApiSecretKey = 'c39395f2-7def-415e-bbd1-53b0bffb6fa4';

// PAYPAL
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID || 'AR8yw06zAXFwc_ZQRa5_BgZp2yzQDasKs_ruPugRHdk4iEdebZBji3ZxzI9Os5rKxMZ2qEFG5bKQQNWc';