import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 150, height: 55, ...sx }}>
      <img alt="Charity Philippines" src="/logo/cpo_logo.png" width="100%" height="100%" />
    </Box>
  );

  if (disabledLink) {
    return (
      <Box sx={{ width: 150, height: 55, ...sx }}>
        <img alt="Charity Philippines" src="/logo/cpo_logo.png" width="100%" height="100%" />
      </Box>
    );
  }

  return (
    <RouterLink to="/">
      {
        <Box sx={{ width: 150, height: 55, ...sx }}>
          <img alt="Charity Philippines" src="/logo/cpo_logo.png" width="100%" height="100%" />
        </Box>
      }
    </RouterLink>
  );
}
