import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CPO_API } from '../../config';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  Charities: [],
  Charity: null,
  sortBy: '',
  filters: {
    category: [],
    isSpecialCharity: false,
    searchBy: 'name',
    searchKey: '',
  },
};

const slice = createSlice({
  name: 'Charities',
  initialState,
  reducers: {
    loadingCharities(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCharitiesSuccess(state, action) {
      state.isLoading = false;
      state.Charities = action.payload;
    },

    getCharityDetailsSuccess(state, action) {
      state.isLoading = false;
      state.Charity = action.payload;
    },

    sortCharitiesBy(state, action) {
      state.sortBy = action.payload;
    },

    filterCharities(state, action) {
      state.filters.category = action.payload.category;
      state.filters.isSpecialCharity = action.payload.isSpecialCharity;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchKey = action.payload.searchKey;
    },
  },
});

export default slice.reducer;

export const { sortCharitiesBy, filterCharities } = slice.actions;

export function getCharities() {
  const token = localStorage.getItem('accessToken');
  return async () => {
      dispatch(slice.actions.loadingCharities());
      try {
          const response = await axios.get(`${CPO_API}/admin/user/charity`, {
              headers: {
                  "authorization": `Bearer ${token}`
              }
          });
          dispatch(slice.actions.getCharitiesSuccess(response.data.data.records));
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  }
}

export function getCharity(id) {
  const token = localStorage.getItem("accessToken");
  return async () => {
      dispatch(slice.actions.loadingCharities());
      try {
          const response = await axios.get(`${CPO_API}/user/info?_id=${id}`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
          dispatch(slice.actions.getCharityDetailsSuccess(response.data.data));
      } catch (error) {
          dispatch(slice.actions.hasError(error.message));
      }
  }
}

