import axios from 'axios';
import { CPO_API } from '../config';
import { handleError, handleResponse } from './reponse';

const CMS_URL = `${CPO_API}/project/cmss`;

const getCMS = () => axios.get(CMS_URL).then(handleResponse).catch(handleError);

const getHome = async () => {
    const result = await getCMS();
    const home = result.data.findLast(e => e.name === "home");
    return home;
}

const getContactUs = async () => {
    const result = await getCMS();
    const contactus = result.data.findLast(e => e.name === "contactus");
    return contactus;
}

const getAboutUs = async () => {
    const result = await getCMS();
    const aboutus = result.data.findLast(e => e.name === "aboutus");
    return aboutus;
}

const getFooter = async () => {
    const result = await getCMS();
    const footer = result.data.findLast(e => e.name === "footer");
    return footer;
}

export const cmsAPI  = {
    getHome,
    getContactUs,
    getAboutUs,
    getFooter
}