// import { useState } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatePicker({ isDisabled, name, ...other }) {
  const { control } = useFormContext();
  // const [value, setValue] = useState("");

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        defaultValue={""}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            {...field}
            disabled={isDisabled === 1}
            fullWidth
            renderInput={(params) => <TextField fullWidth helperText={error?.message} {...params} error={!!error} />}
            {...other}
          />
        )}
      />
    </LocalizationProvider>
  );
}
