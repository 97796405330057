import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Card, Checkbox, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { countries } from '../../../../../_mock';
import { FormProvider, RHFDatePicker, RHFTextField } from '../../../../../components/hook-form';
import { fDateDb } from '../../../../../utils/formatTime';
import NoAmountModal from '../../NoAmountModal';
import { DONATION_TYPE } from '../../../../../config';
import { DonationAPI } from '../../../../../services/donation.services';

export default function CheckForm({ discretionary, projects, causes, disabled, onSuccess }) {

    const [isLoading, setIsLoading] = useState(false);
    const [noAmountOpen, setNoAmountOpen] = useState(false);

    const resolver = yupResolver(Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        phone: Yup.string().required('Required'),
        donationAmount: Yup.string().required('Required'),
        issuingBank: Yup.string().required("Required."),
        checkDate: Yup.string().required("Required."),
        pickupAddress: Yup.string().when('pickup', {
            is: true,
            then: Yup.string().required('Required')
        }),

    }))

    const defaultValues = {
        anonymous: false,
        file: [],
        country: 'PH',
        emailUpdate: false,
        pickup: false,
        sendCheck: false,
    }

    const methods = useForm({ defaultValues, resolver });

    const {
        reset,
        setError,
        handleSubmit,
        setValue,
        trigger,
        watch,
        clearErrors,
        formState: { errors, isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = (data) => {
        if (values.sendCheck === values.pickup) {
            setError('sendCheck', { message: 'Please choose one.', type: 'validate' });
            return;
        }

        if (!values.donationAmount) {
            setNoAmountOpen(true);
            return;
        }

        setIsLoading(true);
        const payload = createPayload({ formData: data, projects, causes });
        DonationAPI.submitDonationInHouse(DONATION_TYPE.IN_HOUSE.CHECK, payload)
            .then((res) => {
                reset(defaultValues);
                setIsLoading(false);
                if (onSuccess) onSuccess();
            })
            .catch((err) => {
                alert(`Encountered an error. Please try again.${err}`);
            });
    }

    useEffect(() => {
        setValue('donationAmount', projects.reduce((prev, val) => prev + val.amount, 0));
    }, [projects])

    useEffect(() => {
        if (values.sendCheck !== values.pickup) setError('sendCheck', null)
    }, [values.sendCheck, values.pickup])

    return (
        <Box>
            <Typography fontWeight={700}>Check</Typography>
            <Typography>Please make your check payable to CharityPhilippines.org and send to:</Typography>
            <Card sx={{ p: 2, mt: 2, mb: 2 }}>
                <Typography variant="body2">
                    Account Name: CharityPhilippines.org
                </Typography>
                <Typography variant="body2">Account Number: 2447244520193</Typography>
                <Typography variant="body2">
                    Bank Name: Metropolitan Bank and Trust Co. (Metrobank)
                </Typography>
                <Typography variant="body2">
                    Bank Address: Upper Ground Floor Farmers Plaza, Araneta Center, Quezon
                    City, Metro Manila, Philippines
                </Typography>
            </Card>
            <Typography>Let us know about your donation by filling up donation details below.</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} md={6}>
                        <RHFTextField autoFocus fullWidth label="First Name" name='firstName' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RHFTextField fullWidth label="Last Name" name='lastName' />
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12}>
                            <RHFTextField fullWidth label="Email" name='email' />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography px={2} variant="caption">We will send your donation receipt to the email address provided.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <RHFTextField fullWidth label="Address" name='address' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete value={countries.find(c => c.code === values.country)}
                            fullWidth
                            onChange={(ev, e) => setValue('country', e.code)} renderInput={(e) => <TextField label="Country" {...e} />}
                            options={countries}
                            getOptionLabel={(e) => e?.label ?? ''}
                            isOptionEqualToValue={(a, b) => a.code === b.code}
                        />
                    </Grid>
                    <Grid item container xs={12} md={6} direction='column' alignItems='start'>
                        <RHFTextField fullWidth name='phone' label='Contact Number' />
                        <Typography variant="caption">We will contact you in case we encounter issues with your donation.</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RHFDatePicker label="Check Date" fullWidth name="checkDate" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RHFTextField fullWidth name='issuingBank' label='Issuing Bank' />
                    </Grid>
                </Grid>
                <Grid mt={5} container alignItems="center">
                    <Grid item xs={2} md={.5}>
                        <Checkbox checked={values.sendCheck} onChange={(e) => {
                            setValue('sendCheck', e.target.checked)
                            if (e.target.checked)
                                setValue('pickup', false)
                        }} />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography fontSize={16}>I will send my check to the address provided.</Typography>
                        <Typography variant='subtitle2' color='red'>{errors.sendCheck?.message}</Typography>
                    </Grid>
                </Grid>
                <Grid mt={2} container alignItems="center">
                    <Grid item xs={2} maxWidth={.5}>
                        <Checkbox checked={values.pickup} onChange={(e) => {
                            setValue('pickup', e.target.checked)
                            if (e.target.checked)
                                setValue('sendCheck', false)
                        }} />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography fontSize={16}>I will request for check pick-up (Available in Metro Manila only.)</Typography>
                        <Typography variant='subtitle2' color='red'>{errors.sendCheck?.message}</Typography>
                    </Grid>
                </Grid>
                {
                    values.pickup &&
                    <>
                        <Typography mt={2} fontSize={16}>I will request for check pick-up in this address. (If not the address you provided above.)</Typography>
                        <RHFTextField fullWidth name='pickupAddress' />
                    </>
                }
                <Grid container spacing={2} alignItems="center" mt={2}>
                    <Typography variant="body1" sx={{ mr: 2 }}>
                        Where did you hear about CharityPhilippines.org?
                    </Typography>
                    <Grid item xs={3}>
                        <Select value={values.charitySource ?? "_"} fullWidth onChange={(e) => setValue('charitySource', e.target.value)} sx={{minWidth: 200}}>
                            <MenuItem value="_">Please Choose</MenuItem>
                            <MenuItem value="Family or Friend">Family or Friend</MenuItem>
                            <MenuItem value="Proactively Looking">Proactively Looking</MenuItem>
                            <MenuItem value="Workplace">Workplace</MenuItem>
                            <MenuItem value="Social Media">Social Media</MenuItem>
                            <MenuItem value="Social Media Advertising">
                                Social Media Advertising
                            </MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                {values.charitySource === "Others" && (
                    <Box width={220}>
                        <RHFTextField name="charityOtherSource" label="Specify Other" />
                    </Box>
                )}
                <Grid container justifyContent='center' py={5}>
                    <Grid item xs={4}>
                        <LoadingButton loading={isLoading} fullWidth size="large" variant="contained" type="submit">SUBMIT</LoadingButton>
                    </Grid>
                </Grid>
            </FormProvider>
            <NoAmountModal open={noAmountOpen} onClose={() => setNoAmountOpen(false)} />
        </Box>
    )
}

const createPayload = ({ discretionary, formData, projects, causes }) => ({
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    contactNumber: formData.phone,
    address: formData.address ? formData.address : ' ',
    country: formData.country,
    donationAmount: formData.donationAmount,
    issuingBank: formData.issuingBank,
    checkDate: formData.checkDate ? fDateDb(formData.checkDate) : '',
    sendCheck: formData.sendCheck,
    pickup: formData.pickup ?? false,
    pickupAddress: formData.pickup ? ((formData.pickupAddress || '') === '' ? formData.address : formData.pickupAddress) : 'N/A', // eslint-disable-line no-nested-ternary
    emailUpdate: formData.emailUpdate,
    charitySource: formData.charitySource === 'Others' ? formData.charityOtherSource : formData.charitySource,
    projects: projects.map(e => ({ ...e, charityName: e.charityDetails.name, id: e._id })),
    discretion: {
        isDiscretion: discretionary,
        causes
    }
})