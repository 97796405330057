import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CPO_API } from '../../config';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  causes: [],
  cause: null,
  sortBy: '',
  filters: {
    category: [],
    isSpecialcause: false,
    searchBy: 'name',
    searchKey: '',
  },
};

const slice = createSlice({
  name: 'causes',
  initialState,
  reducers: {
    loadingcauses(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getcausesSuccess(state, action) {
      state.isLoading = false;
      state.causes = action.payload;
    },

    getcauseDetailsSuccess(state, action) {
      state.isLoading = false;
      state.cause = action.payload;
    },

    sortcausesBy(state, action) {
      state.sortBy = action.payload;
    },

    filtercauses(state, action) {
      state.filters.category = action.payload.category;
      state.filters.isSpecialcause = action.payload.isSpecialcause;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchKey = action.payload.searchKey;
    },

    generalRequestSuccess(state) {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const { sortcausesBy, filtercauses } = slice.actions;

export function getcauses() {
  return async () => {
    dispatch(slice.actions.loadingcauses());
    try {
      const response = await axios.get(`${CPO_API}/cause`);
      dispatch(slice.actions.getcausesSuccess(response.data.data.records));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getcause(id) {
  return async () => {
    dispatch(slice.actions.loadingcauses());
    try {
      // not the real link
      const response = await axios.get(`${CPO_API}/cause/info?_id=${id}`);
      dispatch(slice.actions.getcauseDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addCause(data) {
  const token = localStorage.getItem('accessToken');
  return async () => {
    dispatch(slice.actions.loadingcauses());
    try {
      const response = await axios.post(`${CPO_API}/cause`, data, {
        Authorization: `Bearer ${token}`,
      });
      dispatch(slice.actions.generalRequestSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return Promise.reject();
    }
  };
}

export function updateCause(id, data) {
  const token = localStorage.getItem('accessToken');
  return async () => {
    dispatch(slice.actions.loadingcauses());
    try {
      const response = await axios.put(`${CPO_API}/cause?_id=${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(slice.actions.generalRequestSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return Promise.reject();
    }
  };
}

export function deleteCause(id) {
  const token = localStorage.getItem('accessToken');
  return async () => {
    dispatch(slice.actions.loadingcauses());
    try {
      const response = await axios.delete(`${CPO_API}/cause?_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(slice.actions.generalRequestSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return Promise.reject();
    }
  };
}
