// import axios from '../utils/axios';
import axios from 'axios';
import {handleResponse, handleError} from './reponse';
import { CPO_API } from '../config';

const PROJECT_URL = `${CPO_API}/project`;

const getAllProjects = (name=null, causes = []) => axios.get(`${PROJECT_URL}?keyword=${name ?? ""}&causes=${encodeURIComponent(causes.join(','))}`).then(handleResponse).catch(handleError);
const getAllProjectsSnippets = () => axios.get(PROJECT_URL).then(filterProjectSnippets).catch(handleError) ;
const getProjectDetails = (projectId) => axios.get(`${PROJECT_URL}/info?_id=${projectId}`).then(handleResponse).catch(handleError);

const getProjectsByCharity = (charityName) => axios.get(`${PROJECT_URL}?charityName=${charityName}`).then(handleResponse).catch(handleError);
const getProjectsByCharityId = (charityId) => axios.get(`${PROJECT_URL}/charity/?charityId=${charityId}`).then(handleResponse).catch(handleError);

const getProjectsByCause = (cause) => axios.get(PROJECT_URL).then((res)=>{
  const causeName = (cause === 'All')?null:cause; 
  
  return filterProjectByCause(causeName,res);
}).catch(handleError);

const filterProjectSnippets = (response) =>{ 
 const data = handleResponse(response); 
 const projectSnippets = (data)?data.data.records.filter(p => p.isProjectSnippet):[];
 const filteredProjectSnippets = [];
 // eslint-disable-next-line array-callback-return
 projectSnippets.filter(p => p.isEmergency === true).map(p => {
  filteredProjectSnippets.push(p);
 })

 // eslint-disable-next-line array-callback-return
 projectSnippets.filter(p => p.isEmergency === false).map(p => {
  filteredProjectSnippets.push(p);
 })
 return filteredProjectSnippets; 
}

const filterProjectById = (projectId, response) => {
  const data = handleResponse(response); 
  let project = null;
  if(data && data.filter(p => p.id === projectId && p.isActive)){
    project = data.filter(p => p.id === projectId && p.isActive)[0];
  }  
  
 return project; 
}

// function getProjects(cause = null, keyword = null) {

//   keyword = keyword ? encodeURI(keyword) : keyword;
//   const causeName = (cause === 'All')?null:cause; 

//   console.log("CAUSE", causeName.toString());

//   return async () => {
//       try {
//           console.log('GET', `${PROJECT_URL}${cause ? `?cause=${cause}` : ""}${keyword ? `?keyword=${keyword}` : ""}`);
//           const response = await axios.get(`${PROJECT_URL}${causeName ? `?cause=${causeName}` : ""}${keyword ? `?keyword=${keyword}` : ""}`);
//           return response;
//       } catch (error) {
//           console.log("error at fetching projects", error);
//       }
//   }
// }

const filterProjectByCause = (causes,response) =>{
  const data = handleResponse(response).data.records;
  const filteredData = [];
  let causesList = null;
  if (typeof causes === 'string'){
    causesList = causes.split(',');
  }
  else if (typeof causes === 'object'){
    causesList = causes;
  }
  if (causesList){
    if (causesList){
      causesList.forEach(cause => {
        data.forEach(project => {
          if(project.isActive && project.causes.includes(cause)){
            if(!filteredData.find(e => e._id === project._id)){
              filteredData.push(project)
            }
          }
        })
      })
    }
    
    return filteredData;
  }
  return data;  
}


export const ProjectAPI = {
  getAllProjects,
  getAllProjectsSnippets,
  getProjectDetails,
  getProjectsByCause,
  // getProjects,
  getProjectsByCharity,
  getProjectsByCharityId
}