import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Grid,
  List,
  Stack,
  Popover,
  ListItem,
  ListSubheader,
  CardActionArea,
  Typography,
} from '@mui/material';
// components
import styles from '../../styles/layouts/main/MenuDesktop.module.css';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  // color: theme.palette.text.primary,
  color: '#0275B5',
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none',
  },
}));

// ----------------------------------------------------------------------

MenuDesktopCPO.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuDesktopCPO({ isOffset, isHome, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={styles.top_menu_text}>Bridging Donors to Worthy Causes</div>
      <Stack direction="row" style={{ marginLeft: 24 }}>
        {navConfig.map(
          (link) =>
            link.title !== 'PROFILE' && (
              <MenuDesktopItem
                key={link.title}
                item={link}
                isOpen={open}
                anchorEl={anchorEl}
                onOpen={handleOpen}
                onClose={handleClose}
                isOffset={isOffset}
                isHome={isHome}
              />
            )
        )}
      </Stack>
    </div>
  );
}

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(['item', 'subheader']),
};

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 }),
        }}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

MenuDesktopItem.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

function MenuDesktopItem({ item, isHome, isOpen, anchorEl, isOffset, onOpen, onClose }) {
  const { title, path, children } = item;

  const JOINUS_LINKS = [
    {
      path: '/auth/register-individual',
      linkName: 'INDIVIDUAL DONOR',
    },
    {
      path: '/auth/register-corporate',
      linkName: 'CORPORATE DONOR',
    },
    {
      path: '/auth/register-volunteer',
      linkName: 'VOLUNTEER',
    },
    {
      path: '/auth/register-nonprofit',
      linkName: ' NONPROFIT / PROPONENT',
    },
  ];
  if (children) {
    return (
      <>
        <LinkStyle
          onClick={onOpen}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            ...(isHome && { color: '#0275B5' }),
            ...(isOffset && { color: '#0275B5' }),
            ...(isOpen && { opacity: 0.48 }),
          }}
        >
          {title}
          <Iconify
            icon={isOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            sx={{ ml: 0.5, width: 16, height: 16 }}
          />
        </LinkStyle>
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={onClose}
          placement={'left-start'}
          PaperProps={{
            sx: {
              py: 2,
              px: 3,
              mt: 2.5,
              borderRadius: 0.7,
              width: 300,
              boxShadow: (theme) => theme.customShadows.z24,
            },
          }}
        >
          <List disablePadding>
            {JOINUS_LINKS.map((link, idx) => (
              <Box key={idx} sx={{ my: 0.8 }}>
                <LinkStyle
                  href={link.path}
                  target="_self"
                  rel="noopener"
                  sx={{
                    color: '#0275B5',
                    my: 3,
                  }}
                >
                  {link.linkName}
                </LinkStyle>
              </Box>
            ))}
          </List>
        </Popover>
      </>
    );
  }

  if (title === 'Documentation') {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        rel="noopener"
        sx={{
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' }),
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  return (
    // <LinkStyle
    //   to={path}
    //   component={RouterLink}
    //   end={path === '/'}
    //   sx={{
    //     ...(isHome && { color: 'common.white' }),
    //     ...(isOffset && { color: 'text.primary' }),
    //     '&.active': {
    //       color: 'primary.main',
    //     },
    //   }}
    // >
    //   {title}
    // </LinkStyle>
    <LinkStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      className={styles.top_menu_link_underline}
      sx={{
        ...(isHome && { color: '#0275B5' }),
        ...(isOffset && { color: '#0275B5' }),
        // '&.active': {
        //   color: 'light blue',
        // },

        // '&:before':'',
        // '&:after': {
        //   content: "",
        //   position: 'absolute',
        //   bottom: '-10px',
        //   width: 0,
        //   height: 5,
        //   margin: '5px 0 0',
        //   transition: 'all 0.2s ease-in-out',
        //   transitionDuration: '0.75s',
        //   opacity: 0,
        //   backgroundColor: '#0275B5'
        // }
      }}
    >
      {title}
    </LinkStyle>
  );
}
