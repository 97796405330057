import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'register-individual',
          element: (
            <GuestGuard>
              <RegisterIndividualCPO />
            </GuestGuard>
          ),
        },
        {
          path: 'register-corporate',
          element: (
            <GuestGuard>
              <RegisterCorporateCPO />
            </GuestGuard>
          ),
        },
        {
          path: 'register-volunteer',
          element: (
            <GuestGuard>
              <RegisterVolunteerCPO />
            </GuestGuard>
          ),
        },
        {
          path: 'register-nonprofit',
          element: (
            <GuestGuard>
              <RegisterNonprofitCPO />
            </GuestGuard>
          ),
        },
        {
          path: 'promote-password',
          element: (
            <GuestGuard>
              <PromotePassword />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'register-individual-unprotected', element: <RegisterIndividualCPO /> },
        { path: 'register-corporate-unprotected', element: <RegisterCorporateCPO /> },
        { path: 'register-volunteer-unprotected', element: <RegisterVolunteerCPO /> },
        { path: 'register-nonprofit-unprotected', element: <RegisterNonprofitCPO /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-password/confirm', element: <ConfirmForgotPassword /> },
        {
          path: 'verify/resend-verification',
          element: <ResendVerificationEmail />,
        },
        {
          path: 'verify/resend-confirmation',
          element: <ConfirmResendEmailVerification />,
        },
        {
          path: 'promote-password/confirm',
          element: <ConfirmPromotePassword />,
        },
        {
          path: 'verify/confirmation',
          element: <ConfirmEmailVerification />,
        },
        { path: 'verify', element: <VerifyEmail /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'profile', element: <Donor /> },
        // { path: 'profile/charity', element: <Charity /> },
        { path: 'about-us', element: <AboutCPO /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'success-stories', element: <SuccessStories /> },
        { path: 'success-stories-details/:id', element: <SuccessStoriesDetails /> },
        { path: 'projects', element: <ExploreProjects /> },
        // { path: 'project-cause', element: <PickProjectCause /> },
        { path: 'explore-project', element: <PickProjectCause /> },
        { path: 'project-details', element: <ProjectDetails /> },
        // { path: 'charity', element: <Charity /> },
        { path: ':charityName', element: <Charity /> },
        { path: 'donation', element: <GivingBasket /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'resultpage/:referenceId/:result', element: <ResultPage /> },
        { path: ':charityName/:projectName', element: <ProjectDetails /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResendVerificationEmail = Loadable(lazy(() => import('../pages/auth/ResendVerificationEmail')));
const ConfirmEmailVerification = Loadable(lazy(() => import('../pages/auth/ConfirmEmailVerification')));
const ConfirmResendEmailVerification = Loadable(lazy(() => import('../pages/auth/ConfirmResendEmailVerification')));
const RegisterIndividualCPO = Loadable(lazy(() => import('../pages/auth/RegisterIndividualCPO')));
const RegisterCorporateCPO = Loadable(lazy(() => import('../pages/auth/RegisterCorporateCPO')));
const RegisterVolunteerCPO = Loadable(lazy(() => import('../pages/auth/RegisterVolunteerCPO')));
const RegisterNonprofitCPO = Loadable(lazy(() => import('../pages/auth/RegisterNonprofitCPO')));
const PromotePassword = Loadable(lazy(() => import('../pages/auth/PromotePassword')));
const ConfirmPromotePassword = Loadable(lazy(() => import('../pages/auth/ConfirmPromotePassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ConfirmForgotPassword = Loadable(lazy(() => import('../pages/auth/ConfirmForgotPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const VerifyEmail = Loadable(lazy(() => import('../pages/auth/VerifyEmail')));

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const AboutCPO = Loadable(lazy(() => import('../pages/AboutCPO')));
const Donor = Loadable(lazy(() => import('../pages/profile/Donor')));
// const Charity = Loadable(lazy(() => import('../pages/charity')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const SuccessStories = Loadable(lazy(() => import('../pages/SuccessStories')));
const SuccessStoriesDetails = Loadable(lazy(() => import('../pages/SuccessStoriesDetails')));
const GivingBasket = Loadable(lazy(() => import('../pages/GivingBasket')));
const ProjectDetails = Loadable(lazy(() => import('../pages/ProjectDetails')));
const ExploreProjects = Loadable(lazy(() => import('../pages/ExploreProjects')));
const PickProjectCause = Loadable(lazy(() => import('../pages/PickProjectCause')));
const Charity = Loadable(lazy(() => import('../pages/Charity')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ResultPage = Loadable(lazy(() => import('../pages/resultpage')));
