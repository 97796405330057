import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";

export default function DonationSuccessModal({ open, onClose }) {
    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogContent>
                <Grid container direction='column' alignItems='center' spacing={5} py={10}>
                    <Grid item>
                        <img height={300} alt="donation_success" src="/images/donation_success.png" />
                    </Grid>
                    <Grid item>
                        <Typography>Donation Submitted</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" fontWeight={600}>Thank you!</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                    <Button size="large" variant="contained" onClick={onClose} >CLOSE</Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}