import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Box, Card, Checkbox, Grid, MenuItem, Select, TextField, Typography, Stack, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, useFieldArray, useFormContext } from 'react-hook-form';

import * as Yup from 'yup';
import Iconify from '../../../../../components/Iconify';
import { countries } from '../../../../../_mock';

import { FormProvider, RHFDatePicker, RHFTextField, RHFCheckbox, RHFRadioGroup, RHFSelect } from '../../../../../components/hook-form';
import { fDateDb } from '../../../../../utils/formatTime';
import NoAmountModal from '../../NoAmountModal';
import { DONATION_TYPE } from '../../../../../config';
import { PATH_PAGE } from '../../../../../routes/paths';
import { DonationAPI } from '../../../../../services/donation.services';

export default function VolunteerForm({ discretionary, projects, causes, disabled, onSuccess }) {

    const [isLoading, setIsLoading] = useState(false);
    const [noAmountOpen, setNoAmountOpen] = useState(false);

    const LINK_COLOR = '#0275B5';
    const LIGHT_BLUE = '#F5FBFF';

    const resolver = yupResolver(Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        emailUpdate: Yup.boolean().required('Required'),
        country: Yup.string().required('Required'),
        phone: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        website: Yup.string().required('Required')
    }))

    const defaultValues = {
        country: 'Philippines'
    }

    const methods = useForm({ defaultValues, resolver });

    const {
        reset,
        setError,
        handleSubmit,
        setValue,
        trigger,
        watch,
        clearErrors,
        formState: { errors, isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = (data) => {
        const payload = createPayload({
            discretionary,
            formData: data,
            projects,
            causes
        });
        DonationAPI.submitDonationInHouse(DONATION_TYPE.IN_HOUSE.VOLUNTEER_SERVICE, payload)
            .then((res) => {
                reset(defaultValues);
                setIsLoading(false);
                if (onSuccess) onSuccess();
            })
            .catch((err) => {
                setIsLoading(false)
                alert(`Encountered an error. Please try again.${err}`);
            });
    }

    return (
        <Box>
            <Typography fontWeight={700}>Volunteer for this Project!</Typography>
            <Typography variant="body1">
                Please fill up the Volunteer Form below then we will connect with you to discuss volunteer opportunities for this project.
            </Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} md={6}>
                        <RHFTextField autoFocus fullWidth label="First Name" name='firstName' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RHFTextField fullWidth label="Last Name" name='lastName' />
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12}>
                            <RHFTextField fullWidth label="Email" name='email' />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <RHFTextField fullWidth label="Address" name='address' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete value={countries.find(c => c.code === values.country)}
                            fullWidth
                            onChange={(ev, e) => setValue('country', e.code)} renderInput={(e) => <TextField label="Country" {...e} />}
                            options={countries}
                            getOptionLabel={(e) => e?.label ?? ''}
                            isOptionEqualToValue={(a, b) => a.code === b.code}
                        />
                        <Typography variant='caption' color='red'>{errors.emailUpdate?.message}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RHFTextField name="phone" label="Contact Number*" />
                    </Grid>
                    <Grid item xs={12}>
                        <RHFTextField name="website" label="Website/Social Media URL" />
                    </Grid>
                </Grid>


                <Typography mt={4} sx={{ typography: 'body1' }}>
                    If you have questions and clarifications in partnering with CPO, please send us a message
                    <Link to={PATH_PAGE.contact} component={RouterLink} color={LINK_COLOR} mx="5px">here</Link>
                    or email us at contact@charityphilippines.org.
                </Typography>

                <Grid container alignItems={'center'} mt={2}>
                    <Grid item xs={2} md={.5}>
                        <RHFCheckbox name="emailUpdate" label="" />
                    </Grid>
                    <Grid xs={10} item>
                        <Typography variant="body1" sx={{ mr: 2 }}>
                            I am interested to receive CharityPhilippines.org's email updates
                        </Typography>
                        <Typography variant='caption' color='red'>{errors.emailUpdate?.message}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems={'center'} mt={2} flexWrap={'wrap'}>
                    <Typography variant="body1" sx={{ mr: 2 }}>
                        Where did you hear about CharityPhilippines.org?
                    </Typography>
                    <Grid item>
                        <Box xs={{ mr: 2, width: 220 }}>
                            <RHFSelect name="charitySource">
                                <option value="">Please Choose</option>
                                <option value="Family or Friend">Family or Friend</option>
                                <option value="Proactively Looking">Proactively Looking</option>
                                <option value="Workplace">Workplace</option>
                                <option value="Social Media">Social Media</option>
                                <option value="Social Media Advertising">Social Media Advertising</option>
                                <option value="Others">Others</option>
                            </RHFSelect>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container justifyContent='center' py={5}>
                    <Grid item xs={4}>
                        <LoadingButton loading={isLoading} fullWidth size="large" variant="contained" type="submit">SUBMIT</LoadingButton>
                    </Grid>
                </Grid>
            </FormProvider>
            <NoAmountModal open={noAmountOpen} onClose={() => setNoAmountOpen(false)} />
        </Box>
    )
}

const createPayload = ({ discretionary, formData, projects, causes }) => ({
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    contactNumber: formData.phone,
    address: formData.address ? formData.address : ' ',
    country: formData.country,
    website: formData.website,
    emailUpdate: formData.emailUpdate,
    charitySource: formData.charitySource === 'Others' ? formData.charityOtherSource : formData.charitySource,
    projects: projects.map(e => ({ ...e, charityName: e.charityDetails.name, id: e._id })),
    discretion: {
        isDiscretion: discretionary,
        causes
    }
})