import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jwt-decode';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { AcctApiSecretKey, AcctApiUrl, CPO_API } from '../config';

const BASE_URL = CPO_API || 'https://dev-api.charityphilippines.org';
const LOGIN_URL = `${BASE_URL}/user/login`;
const PROMOTE_PASSWORD_URL = `${BASE_URL}/user/promoteNewPassword`;
const CHANGE_PASSWORD_URL = `${BASE_URL}/user/changePassword`;
const FORGOT_PASSWORD_URL = `${BASE_URL}/user/forgotPassword`;
const SIGNUP_URL = `${BASE_URL}/user/signup`;
const USER_INFO_URL = `${BASE_URL}/user/info`;
const UPDATE_USER_INFO_URL = `${BASE_URL}/user`;
const UPLOAD_PROFILE_URL = `${BASE_URL}/user/upload`;
const VERIFICATION_URL = `${BASE_URL}/user/verify`;
const RESEND_VERIFICATION_URL = `${BASE_URL}/user/resendVerification`;

const CALLBACK_BASE_URL = window.location.origin;
const VERIFY_CALLBACK_URL = `${CALLBACK_BASE_URL}/auth/verify/confirmation`;
const FORGOT_PASSWORD_CALLBACK_URL = `${CALLBACK_BASE_URL}/auth/promote-password`;

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  registerAcctDonor: () => Promise.resolve(),
  resendVerification: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  promoteNewPassword: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  updateUserInfo: () => Promise.resolve(),
  uploadPhoto: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        let user;

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const userToken = jwt(accessToken);

          const response = await axios.get(USER_INFO_URL, {
            params: {
              _id: userToken._id,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const { data, success } = response.data;

          if (success && data) {
            user = data;
          } else {
            user = userToken;
          }

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const verify = async (email, verificationToken) => {
    const response = await axios.patch(VERIFICATION_URL, null, {
      params: {
        email,
        verificationToken,
      },
    });

    return response.data;
  };

  const resendVerification = async (email) => {
    const response = await axios.patch(RESEND_VERIFICATION_URL, {
      email,
      verificationBaseUrl: VERIFY_CALLBACK_URL,
    });
    return response;
  };

  const login = async (email, password) => {
    const response = await axios.post(LOGIN_URL, {
      email,
      password,
    });

    if (response.data.success) {
      const user = jwt(response.data.data.jwt);

      setSession(response.data.data.jwt);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }

    window.localStorage.setItem('userEmail', email);
    return response.data;
  };

  const forgotPassword = async (email) => {
    const response = await axios.patch(
      FORGOT_PASSWORD_URL,
      {
        forgotPasswordCallbackUrl: FORGOT_PASSWORD_CALLBACK_URL,
      },
      {
        params: {
          email,
        },
      }
    );

    return response.data;
  };

  const promoteNewPassword = async (forgotPasswordToken, newPassword, confirmNewPassword, uuid) => {
    const response = await axios.patch(
      PROMOTE_PASSWORD_URL,
      {
        forgotPasswordToken,
        newPassword,
        confirmNewPassword,
      },
      {
        params: {
          id: uuid,
        },
        headers: {
          'content-type': 'application/json',
        },
      }
    );

    return response.data;
  };

  const changePassword = async (currentPassword, newPassword, confirmNewPassword, uuid) => {
    const response = await axios.patch(
      CHANGE_PASSWORD_URL,
      {
        currentPassword,
        newPassword,
        confirmNewPassword,
      },
      {
        params: {
          id: uuid,
        },
        headers: {
          'content-type': 'application/json',
        },
      }
    );

    return response.data;
  };

  const register = async (payload) => {
    const response = await axios.post(SIGNUP_URL, { userInfo: payload, verificationBaseUrl: VERIFY_CALLBACK_URL });
    const { _id } = response.data;

    if (response.data.success) {
      delete payload.password;
      window.localStorage.setItem('userEmail', payload.email);

      dispatch({
        type: 'REGISTER',
        payload: {
          user: payload,
        },
      });
    }

    return response.data;
  };

  // INTEGRATION
  const registerAcctDonor = async (payload) => {
    const token = await axios.post(`${AcctApiUrl}/api/auth_admin`, {}, {headers: {secret_key: AcctApiSecretKey}});

    const registerDonor = await axios.post(`${AcctApiUrl}/api/addDonor`, payload, { headers: {
      secret_key: AcctApiSecretKey,
      authorization: token.data.access_token
    }});

    return registerDonor.data;
  }

  const updateUserInfo = async (uuid, payload) => {
    try {
      const response = await axios.put(UPDATE_USER_INFO_URL, payload, {
        params: {
          _id: uuid,
        },
      });

      return response.data;
    } catch (error) {
      throw Error(error);
    }
  };

  const uploadPhoto = async (mimeType, fileName, base64) => {
    try {
      const response = await axios.post(UPLOAD_PROFILE_URL, { mimeType, key: fileName, body: base64 });
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  };

  const logout = async () => {
    setSession(null);
    window.localStorage.removeItem('userEmail');
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        registerAcctDonor,
        resendVerification,
        verify,
        forgotPassword,
        promoteNewPassword,
        changePassword,
        updateUserInfo,
        uploadPhoto,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
