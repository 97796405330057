import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Link, Stack, Tooltip, IconButton, Box } from '@mui/material';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

SocialButtonsCPO.propTypes = {
  socialColor: PropTypes.string,
};

export default function SocialButtonsCPO({ socialColor, socialLink }) {
  
  const SOCIALS = [
    {
      name: 'FaceBook',
      icon: 'fa6-brands:facebook-square',
      path: socialLink?.facebook,
    },
    {
      name: 'Instagram',
      icon: 'fa-brands:instagram-square',
      path: socialLink?.instagram,
    },
    {
      name: 'Twitter',
      icon: 'fa-brands:twitter-square',
      path: socialLink?.twitter,
    },
    {
      // name: 'Youtube',
      // icon: 'ion:logo-youtube',
      name: 'Tiktok',
      icon: 'ion:logo-tiktok',
      path: socialLink?.tiktok,
    },
    {      
      name: 'LinkedIn',
      icon: 'ion:logo-linkedin',
      path: '',
    }
  ];

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center">
      {SOCIALS.map((social) => {
        const { name, icon, path } = social;
        return path !== null ? (
          <Link
            key={name}
            onClick={() => {
              window.open(path);
            }}
          >
            <Tooltip title={name} placement="top">
              <IconButton
                sx={{
                  mr: 1.2,
                  p: 0,
                  color: socialColor,
                  '&:hover': {
                    bgcolor: alpha(socialColor, 0.08),
                  },
                }}
              >
                <Iconify icon={icon} sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
          </Link>
        ) : (
          <Box key={name}>
            <Tooltip title={name} placement="top">
              <IconButton
                disableRipple
                sx={{
                  mr: 1.2,
                  p: 0,
                  cursor: 'default',
                  color: socialColor,
                  // '&:hover': {
                  //   bgcolor: alpha(socialColor, 0.08),
                  // },
                }}
              >
                <Iconify icon={icon} sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      })}
    </Stack>
  );
}
