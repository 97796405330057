import { useLocation, Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Link, Container, Typography, Fab } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Iconify from '../../components/Iconify';
import { PATH_PAGE } from '../../routes/paths';

// -------------------------------------------------------
export default function StickyHeaderNotification({ show, handleClose }) {
  const theme = useTheme();
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');
  const contactUsURL = `${PATH_PAGE.contact}?type=beta`;  
  const showNotification = pathname === '/' || pathname === '/donation';
  const isGivingBasketPage = pathname === '/donation';

  show = false;
  
  return (
    showNotification && (
      <Box
        sx={{
          bgcolor: '#00AB55',
          py: '12px',
          display: show ? 'block' : 'none',
        }}
      >
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Iconify
            icon={'icon-park-solid:love-and-help'}
            width={50}
            height={50}
            sx={{ color: '#ffffff', mr: 3, display: { xs: 'none', md: 'block' } }}
          />
          {isGivingBasketPage ? (
            <Typography
              fontWeight={'700 !important'}
              sx={{
                typography: 'body2',
                fontSize: '14px !important',
                width: '90%',
                mt: { xs: 0.5, md: 0 },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              Thank you for your interest in supporting our projects. More options for ways to donate, including debit/credit cards, cryptocurrency, PayMaya, Gcash, QR Ph, and WeChat Pay will be available by November.
            </Typography>       
          )
          :
          (
            <Typography
              fontWeight={'700 !important'}
              sx={{
                typography: 'body2',
                fontSize: '14px !important',
                width: '90%',
                mt: { xs: 0.5, md: 0 },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              Thanks for dropping by. We are currently in beta mode. In case you encounter issues while browsing or
              would like to suggest improvements to our website, please click{' '}
              <Link component={RouterLink} to={contactUsURL} sx={{ fontWeight: 700, mx: 0.3, color: '#fff800' }}>
                here
              </Link>
              . Your feedback will be highly appreciated.
            </Typography>
          )}
          <Button
            size="small"
            variant="contained"
            onClick={handleClose}
            sx={{
              backgroundColor: '#ffa500',
              '&:hover': {
                backgroundColor: '#ffb020',
              },
              mt: 1,
              display: { xs: 'block', md: 'none' },
            }}
          >
            Got it
          </Button>
          <Fab
            onClick={handleClose}
            color="primary"
            size="small"
            aria-label="add"
            sx={{ position: 'absolute', right: 20, display: { xs: 'none', md: 'inline-flex' } }}
          >
            <Iconify icon={'carbon:close'} width={30} height={30} sx={{ color: '#ffffff' }} />
            {/* <Iconify icon={'ep:circle-close-filled'} width={30} height={30} sx={{ color: '#ffffff' }} /> */}
          </Fab>
        </Container>
      </Box>
    )
  );
}
