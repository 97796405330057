// import axios from '../utils/axios';
import axios from 'axios';
import {handleResponse, handleError} from './reponse';
import { CPO_API } from '../config';
// const CHARITY_URL = 'https://charityph-website-content.s3.ap-southeast-1.amazonaws.com/contents/charities.json';
const CHARITY_URL = `${CPO_API}/user/info/charity`;
const CHARITY_URL2 = `${CPO_API}/user/charity`;

const getAllCharities = () => axios.get(CHARITY_URL).then(handleResponse).catch(handleError);

// const getCharityDetails = (charityId) => axios.get(CHARITY_URL).then((res)=> filterCharityById(charityId,res)).catch(handleError);
const getCharityDetails = (charityId) => axios.get(CHARITY_URL).then((res)=>{
    filterCharityById(charityId,res)}).catch(handleError);



const filterCharityById = (charityId,response) =>{
  const data = handleResponse(response);
  if(charityId){
    return data.find((c)=> c.id === charityId)[0];
  }

  return null;
}

export const GetCharityName = (charityId,response) =>{
  const data = handleResponse(response);
  if(charityId){
    return data.find((c)=> c.id === charityId)[0];
  }

  return null;
}

const getCharityByKeyword= (keyword=null) => axios.get(`${CHARITY_URL2}?keyword=${encodeURIComponent(keyword) ?? " "}`).then(handleResponse).catch(handleError);


export const CharityAPI = {
  getAllCharities,
  getCharityDetails,
  getCharityByKeyword,
}