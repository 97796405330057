// import axios from '../utils/axios';
import axios from 'axios';
import { CPO_API } from '../config';
import {handleResponse, handleError} from './reponse';

// const CAUSES_URL = 'https://charityph-website-content.s3.ap-southeast-1.amazonaws.com/contents/causes.json';
const CAUSES_URL = `${CPO_API}/cause`;


const getAllCauses = () => axios.get(CAUSES_URL).then(handleResponse).catch(handleError);

export const CausesAPI = {
  getAllCauses,
}