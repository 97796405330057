import { m } from 'framer-motion';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Grid, Link, Container, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
// assets
import { DswdLogo, PcncLogo } from '../../assets';
// components
import Logo from '../../components/Logo';
import SocialButtonsCPO from '../../components/SocialButtonsCPO';
import { varFade, MotionViewport } from '../../components/animate';
import PrivacyPolicy from './PrivacyPolicy';
import { PATH_PAGE } from '../../routes/paths';
import { cmsAPI } from '../../services/cms.service';
import Image from '../../components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  position: 'relative',
  backgroundColor: '#9EBFE0',
}));

// ----------------------------------------------------------------------

const projectImagess3URL = 'https://charity-project-images.s3.ap-southeast-1.amazonaws.com';

export default function MainFooter() {

  const [footerDetails, setFooterDetails] = useState();
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const contactUsURL = `${PATH_PAGE.contact}?type=beta`;  

  const getFooter = async () => {
    const footer = await cmsAPI.getFooter();
    setFooterDetails(footer?.data);
  }

  useEffect(() => {
    getFooter();
  }, [])

  return (
    <>
      <RootStyle>
        <Container sx={{ pt: 1 }} component={MotionViewport}>
          <m.div variants={varFade().inUp}>
         
            <Grid
              container
              justifyContent={{ xs: 'center', md: 'space-between' }}
              sx={{ textAlign: { xs: 'center', md: 'left' } }}
            >
              {/* logo with address */}
              <Grid item xs={8} md={4}>
                <Box
                  sx={{
                    mb: 1,
                  }}
                >
                  <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
                  <Typography variant="body2" sx={{ pr: { md: 5 } }}>
                    Bridging Donors to Worthy Causes
                  </Typography>

                </Box>
  
                <Typography variant="body2" sx={{ pr: { md: 5 } }}>
                  {footerDetails?.address}
                </Typography>
              </Grid>
  
              {/* Accredited */}
              <Grid item xs={8} md={4}>
                <Typography
                  component="p"
                  variant="body2"
                  sx={{
                    textAlign: 'center',
                    mt: { xs: 5, md: 1 },
                    fontSize:".70rem"
                  }}
                >
                  {/* <Link href="" color="inherit">
                    CharityPhilippines.org
                  </Link> */}
                  <span style={{ marginLeft: '4px' }}> The 101 Foundation, Inc., operating under the name of CharityPhilippines.org, is duly registered with and licensed by DSWD to operate as an Auxiliary Social Welfare and Development Agency.</span>
                </Typography>
                {/* <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={{ xs: 'center', md: 'space-between' }}> */}
                <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={{ xs: 'center', md: 'center' }}>
                  <Grid container spacing={1} justifyContent="center" mt="2px">
                    {
                      footerDetails?.accreditedUrlImages?.map((e, i) =>
                        <Grid key={`aui${i}`} item>
                          <a href="https://www.dswd.gov.ph/">
                            <Image sx={{ height: 50 }} src={e} />
                          </a>
                        </Grid>
                      )
                    }
                  </Grid>
           
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      ml: '6px',
                      fontSize: '.70rem',
                      textAlign: 'center',
                      mt:1
                    }}
                  >
                    {footerDetails?.copyright}
                  </Typography>
                </Stack>
              </Grid>
  
              {/* Links and Socials */}
              <Grid item xs={8} md={4}>
                <Box>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                    sx={{ mt: { xs: 5, md: 0 } }}
                  >
                    <Link href={PATH_PAGE.about} color="inherit" variant="button" sx={{ mr: { xs: 0, md: 3 } }}>
                      About Us
                    </Link>
                    <Link href={PATH_PAGE.contact} color="inherit" variant="button">
                      Contact Us
                    </Link>
                  </Stack>
                  <Stack direction="row" justifyContent={{ xs: 'center', md: 'flex-end' }} sx={{ mt: 1 }}>
                    <SocialButtonsCPO socialColor="#000" />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </m.div>
          <Box sx={{ mt: 0, pb: 2, width: 1, display: 'flex', justifyContent: 'center' }}>
            <PrivacyPolicy open={openPrivacy} handleClose={() => setOpenPrivacy(false)} />
            {/* <Typography
              sx={{
                ml: '6px',
                fontSize: '.92rem',
                textAlign: 'center',
              }}
            >
              {footerDetails?.copyright}
            </Typography> */}
          </Box>
        </Container>
      </RootStyle>
    </>
  );
  
}
