import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// @mui
import { Typography, Box, Button, Stack } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import { setFileAttachmentsEvent } from '../../redux/slices/donation';
import { CloseIcon } from '../../theme/overrides/CustomIcons';

const COLOR_LIGHTGRAY = '#e5e5e5';
const COLOR_GRAY = '#7B7B7B';
const COLOR_RED = '#FF4842';

UploadPhoto.propTypes = {
  label: PropTypes.string,
  selectedImages: PropTypes.array.isRequired,
  setSelectedImages: PropTypes.func.isRequired,
};

export default function UploadPhoto({ label, selectedImages, setSelectedImages, error }) {
  const dispatch = useDispatch();
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    dispatch(setFileAttachmentsEvent(selectedFilesArray));
    setSelectedImages((previousImages) => previousImages.concat(selectedFilesArray));

    event.target.value = '';
  };

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
        sx={{ width: 1, minHeight: 100, border: `1px dashed ${error ? COLOR_RED : COLOR_GRAY}`, borderRadius: 1, px: 3, py: 2 }}
      >
        {
          selectedImages.length > 0 ? (
            <Box sx={{ display: 'flex', width: { xs: 1, md: '70%' }, flexWrap: 'wrap'}}>
              {selectedImages.map((file, index) => (
                <Box key={`file-${index}`} position="relative">
                  <Box
                    key={index}
                    component={'button'}
                    onClick={() => setSelectedImages(selectedImages.filter((_, i) => i !== index))}
                    sx={{
                      borderRadius: 2,
                      borderStyle: 'none',
                      width: 24,
                      height: 24,
                      color: '#fff',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 1,
                      background: 'rgb(255,0,0, .5)',
                      cursor: 'pointer',
                      '&:hover': {
                        background: 'rgb(255,0,0)',
                      },
                    }}
                  >
                    <CloseIcon/>
                    {/* Rest of the code... */}
                  </Box>
                  <img src={URL.createObjectURL(file)} alt={`uploaded ${index}`} style={{width: 80, height: 80, borderRadius: 12, objectFit: 'cover'}} />
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                width: { xs: 1, md: '70%' },
              }}
            >
              <Box component={'div'}>
                <Iconify
                  icon={'bxs:image-alt'}
                  sx={{ width: { xs: 40, md: 60 }, height: { xs: 40, md: 60 }, color: COLOR_GRAY }}
                />
              </Box>
              <Typography variant="body2" sx={{ ml: 1, color: COLOR_GRAY, textAlign: { xs: 'center', md: 'left' } }}>
                {label || 'Upload Photo'}
              </Typography>
            </Box>
          )
        }

        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, width: { xs: 1, md: '30%' } }}>
          <Button
            variant="contained"
            component="label"
            sx={{ textTransform: 'none' }}
            startIcon={<Iconify icon={'bxs:image-add'} sx={{ width: 20, height: 20 }} />}
            disabled={selectedImages.length > 0}
          >
            Select a Photo
            <input type="file" onChange={onSelectFile} multiple accept="image/png , image/jpeg, image/webp" hidden />
          </Button>
        </Box>
      </Stack>
      {error && <Typography px={3} variant='caption' color={COLOR_RED}>{error.message}</Typography>}
    </>
  );
}
