import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { CloseIcon } from "../../../theme/overrides/CustomIcons";

export default function NoAmountModal({ open, onClose }) {
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
        >
            <DialogTitle style={{ maxWidth: "80%" }}>
                No amount.
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        onClose();
                    }}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography mt={3}>Please enter donation amount.</Typography>
                <Grid container justifyContent='right'>
                    <Button size="large" onClick={onClose}>Okay</Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}