import { useLocation, Outlet } from 'react-router-dom';
import { useState } from 'react';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooterCPO from './MainFooterCPO';
import MainHeaderCPO from './MainHeaderCPO';
import DiscretionaryDonationFAB from './DiscretionaryDonationFAB';
import DiscretionaryModal from './modals/DiscretionaryModal';
import BasketModal from './modals/BasketModal';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  const [showDiscretioanry, setShowDiscretionary] = useState(false);
  const [showBasket, setShowBasket] = useState(false);

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeaderCPO setShowDiscretionary={setShowDiscretionary} />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      <MainFooterCPO />

      <DiscretionaryModal open={showDiscretioanry} onClose={() => setShowDiscretionary(false)} />

      <BasketModal />

      {/* <DiscretionaryDonationFAB setShowDiscretionary={setShowFromFAB} /> */}
      {/* {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container>
            <Logo sx={{ mb: 1, mx: 'auto' }} />

            <Typography variant="caption" component="p">
              © All rights reserved
              <br /> made by &nbsp;
              <Link href="https://minimals.cc/">minimals.cc</Link>
            </Typography>

            <Typography variant="caption" component="p">
            <Link href="">Privacy Policy</Link><span style={{'marginLeft':'8px'}}>CharityPhilippines.org ©2022 Copyright All rights reserved</span>                            
            </Typography>
          </Container>
        </Box>
      )} */}
    </Stack>
  );
}
