// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function MyAvatarCPO({ ...other }) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.profileUrl}
      alt={user?.firstName}
      sx={{ bgcolor: '#90a4ae' }}
      // color={user?.photoURL ? 'default' : createAvatar(user?.firstName).color}
    >
      <Iconify
        icon={'carbon:user-avatar-filled'}
        sx={{
          width: 1,
          height: 1,
          color: '#cfd8dc',
        }}
      />
      {/* {createAvatar(user?.firstName).name} */}
    </Avatar>
  );
}
