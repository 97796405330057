import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CPO_API } from '../../config';
import { dispatch } from '../store';

const source = 'https://charityph-website-content.s3.ap-southeast-1.amazonaws.com';

const initialState = {
  isLoading: false,
  error: null,
  projects: [],
  project: null,
  revisions: [],
  revision: null,
  sortBy: '',
  filters: {
    category: [],
    isSpecialProject: false,
    searchBy: 'name',
    searchKey: '',
  },
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    loadingProjects(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addProjectSuccess(state, action) {
      state.isLoading = false;
    },

    getProjectsSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    },

    getProjectDetailsSuccess(state, action) {
      state.isLoading = false;
      state.project = action.payload;
    },

    sortProjectsBy(state, action) {
      state.sortBy = action.payload;
    },

    filterProjects(state, action) {
      state.filters.category = action.payload.category;
      state.filters.isSpecialProject = action.payload.isSpecialProject;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchKey = action.payload.searchKey;
    },

    generalRequestSuccess(state) {
      state.isLoading = false;
    },

    getRevisionsSuccess(state, action) {
      state.revisions = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const { sortProjectsBy, filterProjects } = slice.actions;

export function getProjects(charityId = null, keyword = null) {
  keyword = keyword ? encodeURI(keyword) : keyword;
  const token = localStorage.getItem('accessToken');
  return async () => {
      dispatch(slice.actions.loadingProjects());
      try {
          const response = await axios.get(
            `${CPO_API}/project${charityId ? `/charity?charityId=${charityId}` : ''}${
              keyword ? `?keyword=${keyword}` : ''
            }`,
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          );
          dispatch(slice.actions.getProjectsSuccess(response.data.data.records));
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  }
}

export function getProject(id) {
  return async () => {
    dispatch(slice.actions.loadingProjects());
    try {
      const response = await axios.get(`${CPO_API}/project/info?_id=${id}`);
      dispatch(slice.actions.getProjectDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProjectTestimonies(id,status) {
  return async () => {
    dispatch(slice.actions.loadingProjects());
    try {
      const response = await axios.get(`${CPO_API}/project/testimonies/${id}?status=${status}`);
      dispatch(slice.actions.getProjectDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveProject(newProject) {
  const token = localStorage.getItem('accessToken');
  return async () => {
    dispatch(slice.actions.loadingProjects());
    try {
      const response = await axios.post(`${CPO_API}/project`, newProject, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        dispatch(slice.actions.addProjectSuccess());
        return Promise.resolve(response.data);
      }
      dispatch(slice.actions.hasError(response.data.message));
      return Promise.reject();
    } catch (error) {
      dispatch(slice.actions.hasError('Error!'));
      return Promise.reject();
    }
  };
}

export function approveProject(id) {
  return async () => {
    dispatch(slice.actions.loadingProjects());
    try {
      const response = await axios.put(`${CPO_API}/project?_id=${id}&action=APPROVE_PROJECT`);
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return Promise.reject();
    }
  };
}

export function updateProject(id, newDetails) {
  const token = localStorage.getItem("accessToken");
    return async () => {
        dispatch(slice.actions.loadingProjects());
        try{
            const response = await axios.put(`${CPO_API}/project/revise?_id=${id}`, newDetails, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            dispatch(slice.actions.generalRequestSuccess());
            return Promise.resolve(response);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return Promise.reject();
        }
    }
}

export function deleteProject(id) {
  const token = localStorage.getItem('accessToken');
  return async () => {
    dispatch(slice.actions.loadingProjects());
    try {
      const response = await axios.delete(`${CPO_API}/project?_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(slice.actions.generalRequestSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return Promise.reject();
    }
  };
}

export function uploadFiles(file: File){
  const token = localStorage.getItem("accessToken");
  return async () => {
      
      dispatch(slice.actions.loadingProjects());
      
      const toBase64 = async (file) => {
          return new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                  resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
              }
          })
      }

      const file64 = await toBase64(file);

      try{
          const response = await axios.post(`${CPO_API}/project/upload`, {
              "mimeType": "image/jpeg",
              "key": file.name.replace(/\s/g, '_'),
              "body": file64
          }, {
              headers: {'authorization': `Bearer ${token}`}
          });
          // alert(response.data.data.url);
          dispatch(slice.actions.generalRequestSuccess());
          return Promise.resolve(response.data.data.url);
      } catch (error) {
          dispatch(slice.actions.hasError(error.message));
          return Promise.error();
      }
  }
}

export function getRevisions() {
  return async () => {
    dispatch(slice.actions.loadingProjects());
    try {
      const response = await axios.get(`${CPO_API}/project/revise`);
      dispatch(slice.actions.getRevisionsSuccess(response.data.data.records));
      return Promise.resolve();
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return Promise.reject();
    }
  };
}

export function approvedRevision(id) {
  return async () => {
    dispatch(slice.actions.loadingProjects());
    try {
      const response = await axios.put(`${CPO_API}/project?_id=${id}&action=REVISION_UPDATE`);
      dispatch(slice.actions.generalRequestSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return Promise.reject();
    }
  };
}

export function rejectRevision(id) {
  return async () => {
    dispatch(slice.actions.loadingProjects());
    try {
      const response = await axios.patch(`${CPO_API}/project/revise/reject?_id=${id}`);
      dispatch(slice.actions.generalRequestSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return Promise.reject();
    }
  };
}
