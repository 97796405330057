import { useState } from 'react';
import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Popover, List } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const LIGHT_BLUE = '#F5FBFF';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'block',
  minHeight: '100vh',
  padding: theme.spacing(16, 1, 0),
  background: LIGHT_BLUE,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginTop: 0,
  minHeight: 450,
  height: 'fit-content',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3),
  borderRadius: 10,
  background: '#fff',
  [theme.breakpoints.up('md')]: {
    width: 470,
    padding: theme.spacing(5),
    boxShadow: theme.customShadows.z8,
    marginTop: 10,
  },
}));

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: '#0275B5',
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none',
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const JOINUS_LINKS = [
    {
      path: '/auth/register-individual',
      linkName: 'INDIVIDUAL DONOR',
    },
    {
      path: '/auth/register-corporate',
      linkName: 'CORPORATE DONOR',
    },
    {
      path: '/auth/register-volunteer',
      linkName: 'VOLUNTEER',
    },
    {
      path: '/auth/register-nonprofit',
      linkName: ' NONPROFIT / PROPONENT',
    },
  ];

  return (
    <Page title="Login">
      <RootStyle>
        <LogoOnlyLayout />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            PaperProps={{
              sx: {
                py: 2,
                px: 3,
                mt: 2.5,
                borderRadius: 0.7,
                width: 300,
                boxShadow: (theme) => theme.customShadows.z24,
              },
            }}
          >
            <List disablePadding>
              {JOINUS_LINKS.map((link, idx) => (
                <Box key={idx} sx={{ my: 0.8 }}>
                  <LinkStyle
                    href={link.path}
                    target="_self"
                    rel="noopener"
                    sx={{
                      color: '#0275B5',
                      my: 3,
                    }}
                  >
                    {link.linkName}
                  </LinkStyle>
                </Box>
              ))}
            </List>
          </Popover>
          <ContentStyle>
            <Box sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Sign in
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
            </Box>

            <LoginForm />
            <Box component={'div'} sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography component={'span'}>Don't have an account?</Typography>
              <Link
                component="button"
                onClick={handleOpen}
                sx={{
                  ml: 1,
                  fontSize: 16,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Sign up Now
                <Iconify
                  icon={'akar-icons:chevron-down'}
                  sx={{
                    ml: 0.3,
                    width: 16,
                    height: 16,
                    color: (theme) => theme.palette.primary.main,
                  }}
                />
              </Link>
            </Box>
          </ContentStyle>
        </Box>
      </RootStyle>
    </Page>
  );
}
