import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Container,
  Badge,
  IconButton,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import Slider from 'react-slick';
import Iconify from '../../components/Iconify';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// route
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layout
import AccountPopoverCPO from './AccountPopoverCPO';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
//
import MenuDesktopCPO from './MenuDesktopCPO';
import MenuMobile from './MenuMobile';
import StickyHeaderNotification from './StickyHeaderNotification';
import StickyDiscretionaryDonation from './StickyDiscretionaryDonation';
// import DiscretionaryModal from './DiscretionaryModal';
import DiscretionaryDonationFAB from './DiscretionaryDonationFAB';
import { setBasketModal } from '../../redux/slices/donation';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  backgroundColor: 'rgb(255,255,255)',
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeaderCPO({ setShowDiscretionary }) {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const [show, setShow] = useState(false);
  // const [showDiscretionary, setShowDiscretionary] = useState(true);
  // const [showDiscretionaryModal, setShowDiscretionalModal] = useState(false);
  const { user, isAuthenticated } = useAuth();

  const handleShow = () => setShow(true);
  const theme = useTheme();

  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'md');

  const showNotification = pathname === '/'; // || pathname === '/donation';

  const { projects } = useSelector((store) => store.donation);
  const dispatch = useDispatch();

  const navConfig = isAuthenticated
    ? [
        {
          title: 'PROFILE',
          icon: null,
          path: PATH_PAGE.profile,
        },
        {
          title: 'EXPLORE PROJECTS',
          icon: null,
          path: PATH_PAGE.projectCause,
        },
        {
          title: 'ABOUT US',
          icon: null,
          path: PATH_PAGE.about,
        },
        {
          title: 'CONTACT US',
          icon: null,
          path: PATH_PAGE.contact,
        },
      ]
    : [
        {
          title: 'EXPLORE PROJECTS',
          icon: null,
          path: PATH_PAGE.projectCause,
        },
        {
          title: 'JOIN US',
          icon: null,
          path: '/',
          children: [
            {
              subheader: '',
              items: [
                { title: 'INDIVIDUAL DONOR', path: PATH_AUTH.registerIndividual },
                { title: 'CORPORATE DONOR', path: PATH_AUTH.registerCorporate },
                { title: 'VOLUNTEER', path: PATH_AUTH.registerVolunteer },
                { title: 'NONPROFIT/PROPONENT', path: PATH_AUTH.registerNonprofit },
              ],
            },
          ],
        },
        {
          title: 'SUCCESS STORIES',
          icon: null,
          path: PATH_PAGE.SuccessStories,
        },
        {
          title: 'ABOUT US',
          icon: null,
          path: PATH_PAGE.about,
        },
        {
          title: 'CONTACT US',
          icon: null,
          path: PATH_PAGE.contact,
        },
        
      ];

  useEffect(() => {
    if (showNotification) {
      handleShow();
    } else {
      setShow(false);
    }
  }, [showNotification]);

  // DISCRETIONARY MODAL ~~~~~
  // useEffect(() => {
  //   if(showFromFAB !== showDiscretionaryModal){
  //     setShowDiscretionalModal(showFromFAB)
  //   }
  // }, [showFromFAB])

  // useEffect(() => {
  //   if(showFromFAB !== showDiscretionaryModal){
  //     setShowFromFAB(showDiscretionaryModal)
  //   }
  // }, [showDiscretionaryModal])

  const setShowBasket = () => {
    dispatch(setBasketModal(true));
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <StickyHeaderNotification show={show} handleClose={() => setShow(false)} />
      {/* <StickyDiscretionaryDonation show={showDiscretionary} handleClose={() => setShowDiscretionary(false)} showModal={() => setShowDiscretionalModal(true)} /> */}
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo />

          {isDesktop && <MenuDesktopCPO isOffset={isOffset} isHome={showNotification} navConfig={navConfig} />}

          <Box sx={{ flexGrow: 1 }} />
          {/* {isAuthenticated} */}
          <Box sx={{ display: 'inherit' }}>
            <Button
              variant="outlined"
              startIcon={
                <Badge
                  badgeContent={projects.length}
                  invisible={projects.length === 0}
                  sx={{
                    '&	.MuiBadge-badge': {
                      backgroundColor: '#FFD580',
                      color: '#ffffff',
                      height: '15px',
                      minWidth: '15px',
                      p: '0 4px',
                      fontSize: '0.65rem',
                    },
                  }}
                >
                  <Iconify icon={'entypo:shopping-cart'} width={24} height={24} />
                </Badge>
              }
              sx={{
                color: 'orange',
                fontSize: 12,
                marginRight: '10px',
                border: 'none',
                display: { xs: 'none', md: 'inherit' },
                '&:hover': {
                  color: 'orange',
                  border: 'none',
                  backgroundColor: 'transparent',
                  opacity: 0.6,
                },
              }}
              onClick={() => {
                setShowBasket();
              }}
            >
              GIVING BASKET
            </Button>

            <IconButton
              sx={{
                display: { xs: 'inherit', md: 'none' },
              }}
              onClick={() => {
                setShowBasket();
                // window.location.href = `/donation`;
              }}
            >
              <Badge
                badgeContent={projects.length}
                invisible={projects.length === 0}
                sx={{
                  '&	.MuiBadge-badge': {
                    backgroundColor: '#FFD580',
                    color: '#ffffff',
                    height: '15px',
                    minWidth: '15px',
                    p: '0 4px',
                    fontSize: '0.65rem',
                  },
                }}
              >
                <Iconify
                  icon={'entypo:shopping-cart'}
                  width={24}
                  height={24}
                  sx={{
                    color: 'orange',
                  }}
                />
              </Badge>
            </IconButton>

            {isAuthenticated ? (
              <AccountPopoverCPO />
            ) : (
              <Button
                variant="text"
                href={PATH_AUTH.login}
                sx={{
                  fontSize: 12,
                  opacity: 0.8,
                  mr: 1,
                  color: '#000',
                  display: { xs: 'none', md: 'inherit' },
                }}
              >
                SIGN IN
              </Button>
            )}
            {/* <div style={{ borderRight: '2px solid rgb(224, 224, 224, .6)', width: '6px' }}>&nbsp;</div> */}
            {/* <Button
              variant="contained"
              sx={{
                fontSize: 12,
                marginLeft: '20px',
                display: { xs: 'none', md: 'inherit' },
              }}
              onClick={() => {
                window.location.href = `/explore-project`;
              }}
            >
              QUICK GIVE
            </Button> */}
            {/* <Button
              variant="contained"
              sx={{
                fontSize: 12,
                marginLeft: '20px',
                display: { xs: 'none', md: 'inherit' },
              }}
              onClick={() => {
                setShowDiscretionary(true);
                // window.location.href = `/explore-project`;
              }}
            >
              QUICK GIVE
            </Button> */}
          </Box>

          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={showNotification} navConfig={navConfig} />}
        </Container>
      </ToolbarStyle>
      {isOffset && <ToolbarShadowStyle />}
      {/* <DiscretionaryModal open={showDiscretionaryModal} onClose={() =>{setShowDiscretionalModal(false)}}/> */}
    </AppBar>
  );
}
