const { default: axios } = require("axios");

const PAYPAL_API = 'https://api-m.sandbox.paypal.com';

// const ngrokTestUrl = 'https://af28-2001-4451-411c-d300-7cb6-8042-6337-7ce3.ngrok-free.app';
const recurringPlanApi = "https://dev-api.charityphilippines.org/donation/recurring/plan"
// const recurringPlanApi = "http://localhost:3010/getDonationPlan"

// const getToken = async () => {
//     const data = await axios.get(`${ngrokTestUrl}/getToken`);
//     return data.data.access_token;
// }

const getPlan = async ({ giverType, projects, frequency, currency, discretionary, causes }) => {
    const params = {
        projects,
        giverType,
        frequency,
        currency,
        discretionary: discretionary === true,
    }
    if (causes) {
        params.causes = causes;
    }
    const data = await axios.post(recurringPlanApi, params);
    return data.data;
}

export {
    // getToken,
    getPlan
}