import FileResizer from "react-image-file-resizer";

const resizeImg = async (file, maxSize) => new Promise((resolve) => {
    FileResizer.imageFileResizer(file, maxSize, maxSize, 'JPEG', 100, 0,
        uri => {
            resolve(uri);
        }, 'base64');
});

function urltoFile(url, filename, mimeType) {
    if (url.startsWith('data:')) {
        const arr = url.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[arr.length - 1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) { // eslint-disable-line no-plusplus
            u8arr[n] = bstr.charCodeAt(n);
        }
        const file = new File([u8arr], filename, { type: mime || mimeType });
        return Promise.resolve(file);
    }
    return fetch(url)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], filename, { type: mimeType }));
}

export async function createResizedFile(fileUrl) {
    let newFile = fileUrl;
    let maxSize = 1000;
    while (newFile.size / 1024 > 500) {
        const newFileBase64 = await resizeImg(newFile, maxSize); // eslint-disable-line no-await-in-loop
        newFile = await urltoFile(newFileBase64, fileUrl.name, fileUrl.type); // eslint-disable-line no-await-in-loop
        maxSize -= 100;
    }
    return newFile;
}

export async function convertImagesToBase64STring(images) {
    return new Promise((resolve, reject) => {
        const imagesTotalCount = images.length;
        const imagesBase64 = [];
        let imagesCounter = 0;
        if (imagesTotalCount > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                imagesBase64.push(reader.result);
                // eslint-disable-next-line no-plusplus
                imagesCounter++;
                if (imagesCounter < imagesTotalCount) {
                    reader.readAsDataURL(images[imagesCounter]);
                } else {
                    resolve(imagesBase64);
                }
            };
            reader.readAsDataURL(images[imagesCounter]);
        }
    });
};